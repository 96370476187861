import React from "react"
import PropTypes from "prop-types"

import Header from "./header"
import "../styles/layout.css"

const AppTermsLayout = ({ homeLink = true, children }) => {

  return (
    <>
      <Header homeLink={homeLink}/>
      <main>{children}</main>
    </>
  )
}

AppTermsLayout.propTypes = {
  children: PropTypes.node.isRequired,
  homeLink: PropTypes.bool,
  isTermsPage: PropTypes.bool,
}

export default AppTermsLayout
